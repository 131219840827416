import { useQuery } from '@apollo/client';
import styled from '@emotion/styled';
import Button from '@material-ui/core/Button';
import { ReactComponent as InfoIcon } from 'assets/icons/info.svg';
import { ReactComponent as ScorecardIcon } from 'assets/icons/scorecard.svg';
import { ContainerLoading } from 'components/loading';
import { VIEWS } from 'constants/views';
import ErrorComponent from 'pages/error-boundary/ErrorComponent';
import PageHeader from 'pages/page-header/PageHeader';
import employeeQuery, { EmployeeResult, fullEmployeeQueryByBadgeId } from 'queries/employee';
import { SalesEntitySearchResult } from 'queries/scorecardSearch';
import { useCallback, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import getReadPermissions from 'utilities/readPermissions';

import { Maybe } from '../../apollo/types';
import { MenuOptions } from '../../components/dropdown/types';
import InfoModal from '../../components/info-modal';
import { Routes } from '../../constants/routes';
import mixpanel from '../../utilities/mixpanel';
import AthleteAverage from './athlete-averages';
import AthleteProfile from './AthleteProfile';
import CareerMetrics from './career-metrics';
import CompetitionScore from './competition-score';
import ContractTypeCircles from './contract-type-circles';
import Headcount from './headcount';
import LeagueLevel from './league-level';
import LeagueScore from './league-score';
import OrganizationProfile from './OrganizationProfile';
import ScorecardBanner from './ScorecardBanner';
import ScorecardSearch from './ScorecardSearch';
import Trends from './trends';
import WeeklyAverage from './weekly-average';
import WeeklyWins from './weekly-wins';

type NavigationState = { navigationEntityId: string; navigationFullName: string; navigationType: string };

const Scorecard = () => {
  let menuOptions: MenuOptions[] = [];
  const { navigationEntityId, navigationFullName, navigationType } = useLocation<NavigationState>()?.state || {};
  const history = useHistory();

  const [scorecardView, setScorecardView] = useState(navigationType || VIEWS.ATHLETE);

  const athleteId = scorecardView === VIEWS.ATHLETE ? navigationEntityId : '';
  const orgId = scorecardView !== VIEWS.ATHLETE ? navigationEntityId : '';

  const [currentOrgId, setCurrentOrgId] = useState(orgId);
  const [entityId, setEntityId] = useState(athleteId);
  const [bannerDisplay, setBannerDisplay] = useState(navigationFullName || '');
  const fullEmployeeQuery = entityId ? fullEmployeeQueryByBadgeId : employeeQuery;
  const options: { [k: string]: any } = { fetchPolicy: 'cache-and-network' };
  const [open, setOpen] = useState(false);
  const handleModalOpen = useCallback(() => setOpen(true), []);
  const handleModalClose = useCallback(() => setOpen(false), []);

  if (entityId) {
    options.variables = { badgeId: entityId };
  }
  const { loading, error, data } = useQuery<EmployeeResult>(fullEmployeeQuery, options);

  useEffect(() => {
    mixpanel.track('Viewed Scorecard page');
  }, []);

  if (error) {
    return <ErrorComponent />;
  }

  if (loading && !data) {
    return <ContainerLoading />;
  }

  const orgs = data?.employee.salesProfile?.primarySellingLocation?.ancestryConnection?.organizations;
  const readPermissions = getReadPermissions(data?.employee.groups, orgs);

  if (readPermissions && readPermissions.length > 0) {
    menuOptions = readPermissions.map(view => ({
      value: view.type,
      display: view.displayName,
      onOptionClick: () => {
        changeView(view.type);
        mixpanel.track(`Scorecard view type changed to ${view.displayName}`);
      },
    }));
  }

  // add athlete view to the top of the array
  menuOptions.unshift({
    value: VIEWS.ATHLETE,
    display: bannerDisplay || 'My Stats',
    onOptionClick: () => {
      changeView(VIEWS.ATHLETE);
      mixpanel.track('Scorecard view type changed to My Stats');
    },
  });

  const changeView = (type: string) => {
    setScorecardView(type);
    if (type !== VIEWS.ATHLETE && orgs) {
      const currentOrgViewObj = orgs.find(org => org.type === type);
      if (currentOrgViewObj) setCurrentOrgId(currentOrgViewObj.id);
    }
  };

  const handleClickSearchResult = (result: Maybe<SalesEntitySearchResult> | undefined) => {
    if (result) {
      const view = result.type === 'TEAM' ? VIEWS.OFFICE : result.type;
      setEntityId(result.id);
      setBannerDisplay(result.name);
      setScorecardView(view);

      if (view !== VIEWS.ATHLETE) {
        setCurrentOrgId(result.id);
      }
    }
  };

  const clearScorecard = () => {
    history.replace(Routes.SCORECARD, {});
    setEntityId('');
    setBannerDisplay('');
    setCurrentOrgId('');
    setScorecardView(VIEWS.ATHLETE);
  };

  return (
    <div>
      <PageHeader
        title="Scorecard"
        icon={<ScorecardIcon fill="white" />}
        options={!bannerDisplay ? menuOptions : []} // remove menu options if we're viewing another athlete or org
        leftButtons={
          <>
            <StyledButton
              variant="text"
              key="information"
              color="inherit"
              startIcon={<InfoIcon />}
              onClick={handleModalOpen}
            >
              <p>Information</p>
            </StyledButton>
            <ScorecardSearch handleClickSearchResult={handleClickSearchResult} />
          </>
        }
      />
      {bannerDisplay && <ScorecardBanner clearScorecard={clearScorecard} bannerDisplay={bannerDisplay} />}
      {data && scorecardView === VIEWS.ATHLETE && (
        <>
          <AthleteProfile badgeId={data.employee?.badgeId} />
          <LeagueScore entityId={data.employee?.badgeId} type={VIEWS.ATHLETE} />
          <LeagueLevel entityId={data.employee?.badgeId} type={VIEWS.ATHLETE} />
          <ContractTypeCircles entityId={data.employee?.badgeId} type={VIEWS.ATHLETE} />
          <Trends entityId={data.employee?.badgeId} type={VIEWS.ATHLETE} />
          <CompetitionScore entityId={data.employee?.badgeId} type={VIEWS.ATHLETE} />
          <WeeklyWins entityId={data.employee?.badgeId} type={VIEWS.ATHLETE} />
          <WeeklyAverage entityId={data.employee?.badgeId} type={VIEWS.ATHLETE} />
          <CareerMetrics entityId={data.employee?.badgeId} type={VIEWS.ATHLETE} />
        </>
      )}
      {data && scorecardView !== VIEWS.ATHLETE && currentOrgId && (
        <>
          <OrganizationProfile orgId={currentOrgId} />
          <LeagueScore entityId={currentOrgId} type={scorecardView} />
          {scorecardView === VIEWS.OFFICE && <LeagueLevel entityId={currentOrgId} type={scorecardView} />}
          <ContractTypeCircles entityId={currentOrgId} type={scorecardView} />
          <Trends entityId={currentOrgId} type={scorecardView} />
          <CompetitionScore entityId={currentOrgId} type={scorecardView} />
          {scorecardView === VIEWS.OFFICE && <WeeklyWins entityId={currentOrgId} type={scorecardView} />}
          <WeeklyAverage entityId={currentOrgId} type={scorecardView} />
          <AthleteAverage entityId={currentOrgId} />
          <Headcount entityId={currentOrgId} />
          <CareerMetrics entityId={currentOrgId} type={scorecardView} />
        </>
      )}
      <InfoModal open={open} handleClose={handleModalClose} />
    </div>
  );
};

const StyledButton = styled(Button)`
  min-width: auto;

  svg {
    fill: ${p => p.theme.palette.common.white};
  }

  ${p => p.theme.breakpoints.up('sm')} {
    svg {
      margin-right: ${p => p.theme.spacing(1)}px;
    }
  }

  ${p => p.theme.breakpoints.down('sm')} {
    p {
      display: none;
    }
  }
`;

export default Scorecard;
