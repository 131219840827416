import styled from '@emotion/styled';
import Button from '@material-ui/core/Button';
import CustomizeIcon from 'assets/icons/CustomizeIcon';
import DownloadIcon from 'assets/icons/DownloadIcon';
import { ReactComponent as InfoIcon } from 'assets/icons/info.svg';
import { ReactComponent as PollIcon } from 'assets/icons/poll.svg';
import { CustomSalesReport, SalesReportType } from 'queries/customerReports';
import { useCallback, useState } from 'react';
import { Black, Globals } from 'styles/constants/colors';
import { range } from 'utilities/dates';

import InfoModal from '../../components/info-modal';
import mixpanel from '../../utilities/mixpanel';
import PageHeader from '../page-header/PageHeader';
import { CustomizeOptions } from '.';
import CustomReportsModal from './CustomReportsModal';

const Header = ({
  reports,
  openCustomizeModal,
  onClickUpdateReports,
  reportName,
  setCustomizeOptions,
  setReportName,
  setSelectedAreaName,
  clearHistory,
  setReportType,
  onDownloadClick,
  disableDownload,
}: {
  onClickUpdateReports: (arg0: CustomSalesReport[]) => void;
  reports: CustomSalesReport[] | undefined;
  clearHistory: () => void;
  openCustomizeModal: () => void;
  reportName: string;
  setSelectedAreaName: (arg0: string) => void;
  setCustomizeOptions: (arg0: CustomizeOptions) => void;
  setReportName: (arg0: string) => void;
  setReportType: (arg0: SalesReportType) => void;
  onDownloadClick: () => void;
  disableDownload: boolean;
}) => {
  const [openCustomReportsModal, setOpenCustomReportsModal] = useState(false);
  const [open, setOpen] = useState(false);
  const handleModalOpen = useCallback(() => setOpen(true), []);
  const handleModalClose = useCallback(() => setOpen(false), []);
  let reportOptions;

  if (reports) {
    reportOptions = [...reports];
  }

  const dropdownOptions: { value: string; display: string; onOptionClick: () => void }[] | undefined =
    reportOptions &&
    reportOptions
      .filter(x => x.visible)
      .map(option => {
        const dates = range[option.period.toLowerCase()]();
        return {
          value: option.salesReportType,
          display: option.name,
          onOptionClick: () => {
            clearHistory();
            mixpanel.track(`Breakdown report changed to ${option.name}`);
            setReportType(option.salesReportType);
            setReportName(option.name);
            setSelectedAreaName(option.area.toUpperCase());
            setCustomizeOptions({
              level: option.level,
              area: option.areaId,
              period: option.period,
              dates: dates,
              view: option.view,
            });
          },
        };
      });

  return (
    <>
      <PageHeader
        title="Breakdown"
        icon={<PollIcon style={{ fill: 'white' }} />}
        optionSelected={reportName}
        options={
          dropdownOptions &&
          dropdownOptions.concat({
            value: 'EDIT',
            display: 'Edit Reports',
            onOptionClick: () => {
              setOpenCustomReportsModal(true);
              mixpanel.track('Breakdown edit reports modal opened');
            },
          })
        }
        leftButtons={[
          <StyledButton
            variant="text"
            key="information"
            color="inherit"
            startIcon={<InfoIcon />}
            onClick={handleModalOpen}
          >
            <p>Information</p>
          </StyledButton>,
          <StyledButton
            disabled={disableDownload}
            onClick={() => {
              onDownloadClick();
              mixpanel.track('Breakdown report downloaded');
            }}
            variant="text"
            key="download"
            color="inherit"
            startIcon={<DownloadIcon />}
          >
            <p>Download</p>
          </StyledButton>,
          <StyledButton
            variant="text"
            key="customize"
            color="inherit"
            startIcon={<CustomizeIcon />}
            onClick={() => {
              openCustomizeModal();
              mixpanel.track('Breakdown customize modal opened');
            }}
          >
            <p>Customize</p>
          </StyledButton>,
        ]}
      />
      <CustomReportsModal
        reports={reports}
        onClickUpdateReports={onClickUpdateReports}
        open={openCustomReportsModal}
        onClose={() => setOpenCustomReportsModal(false)}
      />
      <InfoModal open={open} handleClose={handleModalClose} />
    </>
  );
};

const StyledButton = styled(Button)`
  min-width: auto;

  span {
    margin: 0;
  }

  svg {
    fill: ${p => (p.disabled ? Black.DARK : Globals.WHITE)};
  }

  p {
    margin-left: 8px;
    color: ${p => (p.disabled ? Black.DARK : Globals.WHITE)};

    ${p => p.theme.breakpoints.down('sm')} {
      display: none;
    }
  }
`;

export default Header;
